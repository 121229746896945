import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getClientConfig } from "clientConfig";
import Image from "next/image";

import ImageCarousel from "marketing/components/ImageCarousel";

import amaSrc from "../images/companyLogos/ama.png";
import birzulisSrc from "../images/companyLogos/birzulis.png";
import iconSrc from "../images/companyLogos/icon.png";
import intraxSrc from "../images/companyLogos/intrax.png";
import langeSrc from "../images/companyLogos/lange.png";
import wardSrc from "../images/companyLogos/ward.png";
import evolveSrc from "../images/companyLogos/evolve.png";
import peledSrc from "../images/companyLogos/peled.png";
import sketchhouseSrc from "../images/companyLogos/sketchhouse.png";
import dfpSrc from "../images/companyLogos/dfp.png";
import fairfaxSrc from "../images/companyLogos/fairfaxCountyVirginia.png";
import municipalityOfAnchorageSrc from "../images/companyLogos/municipalityOfAnchorage.png";
import pikesPeakSrc from "../images/companyLogos/pikesPeak.png";
import newCastleCountySrc from "../images/companyLogos/newCastleCounty.png";
import socialProofCalculator from "homepage/images/socialProofCalculator.svg";
import socialProofProject from "homepage/images/socialProofProject.svg";
import socialProofClock from "homepage/images/socialProofClock.svg";

import { SecondaryButtonLink, SubHeading, Text } from "ui";
import { spacing125 } from "ui/spacing";
import { grey50 } from "ui/colors";
import { desktop, mobile } from "uiKit/theme/mediaQueries";

const COMPANY_IMAGES = [
    [intraxSrc.src, "Intrax Consulting Engineers"],
    [evolveSrc.src, "Evolve Design Works"],
    [wardSrc.src, "Ward Engineering"],
    [fairfaxSrc.src, "Fairfax County Virginia"],
    [birzulisSrc.src, "Birzulis Associates"],
    [sketchhouseSrc.src, "Sketch House Design"],
    [pikesPeakSrc.src, "Pikes Peak Regional Building Department"],
    [langeSrc.src, "Lange Structural Group"],
    [dfpSrc.src, "DFP Planning & Design"],
    [peledSrc.src, "Peled Engineering"],
    [newCastleCountySrc.src, "New Castle County"],
    [amaSrc.src, "AM-A Consulting Engineers"],
    [iconSrc.src, "Icon Consulting Engineers"],
    [municipalityOfAnchorageSrc.src, "Municipality of Anchorage"],
];

interface State {
    sheets: string;
}

export default function TrustedBySection() {
    const [sheets, setSheets] = useState("10+ million");

    useEffect(() => {
        fetch(`${getClientConfig().baseUrl || ""}/api/analytics/sheets/count`)
            .then(function (response) {
                return response.json();
            })
            .then(
                (json) => {
                    const multipleOfAMillion = Math.floor(
                        Number(json.data.attributes.count) / 1000000,
                    );

                    // Display a meaningful number for local development
                    if (multipleOfAMillion === 0) {
                        setSheets(
                            `${Number(
                                json.data.attributes.count,
                            ).toLocaleString()}`,
                        );
                    } else {
                        setSheets(`${multipleOfAMillion}+ million`);
                    }
                },
                () => {
                    // We don't care.
                },
            );
    }, []);

    return (
        <Root>
            <SocialProofContainer>
                <SocialProofHeading>
                    <SubHeading bold>
                        Join 3,500+ engineers and designers increasing design
                        speed and accuracy
                    </SubHeading>
                    <SecondaryButtonLink href="/customers">
                        <Text>See Customer Stories</Text>
                    </SecondaryButtonLink>
                </SocialProofHeading>
                <SocialProofStats>
                    <div>
                        <Image
                            src={socialProofCalculator}
                            alt="A graphic illustration of a graph overlaid with a calculator"
                            width="120"
                            height="120"
                        />
                        <SubHeading bold>{sheets}</SubHeading>
                        <Text>designs created</Text>
                    </div>
                    <div>
                        <Image
                            src={socialProofProject}
                            alt="A graphic illustration of documents overlaid with a house"
                            width="120"
                            height="120"
                        />
                        <SubHeading bold>70k+</SubHeading>
                        <Text>projects annually</Text>
                    </div>
                    <div>
                        <Image
                            src={socialProofClock}
                            alt="A graphic illustration of an analog clock"
                            width="120"
                            height="120"
                        />
                        <SubHeading bold>30%</SubHeading>
                        <Text>time saving</Text>
                    </div>
                </SocialProofStats>
            </SocialProofContainer>
            <ImageCarousel images={COMPANY_IMAGES} />
        </Root>
    );
}

const Root = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    margin-bottom: 50px;
    padding: 0 20px;
    width: 100%;
    text-align: center;
`;

const SocialProofContainer = styled.div`
    display: block;
    padding: 40px;
    background: ${grey50};
    border-radius: 4px;

    ${desktop`
        display: flex;
        gap: 100px;
        align-items: center;
        padding: 80px;
    `}
`;

const SocialProofHeading = styled.div`
    text-align: left;
    flex: 1;
`;

const SocialProofStats = styled.div`
    & > * {
        display: inline-block;
        padding: ${spacing125};

        ${mobile`
            display: block;
        `}
    }
`;
