import React from "react";
import styled from "styled-components";

interface IProps {
    images: string[][];
}
interface IState {
    sliding: boolean;
    images: string[][];
}
class ImageCarousel extends React.Component<IProps, IState> {
    readonly state: IState = {
        sliding: false,
        images: ([] as string[][]).concat(this.props.images),
    };
    private sliderInterval?: number;

    public componentDidMount() {
        this.sliderInterval = window.setInterval(this.rotateSlider, 5000);
    }
    public componentWillUnmount() {
        clearInterval(this.sliderInterval);
    }

    public render() {
        return (
            <Root>
                <Slider
                    onTransitionEnd={this.updateImages}
                    sliding={this.state.sliding}
                    style={{
                        width: `${this.state.images.length * 300}px`,
                    }}
                >
                    {this.state.images.map((image, idx) => (
                        <img src={image[0]} alt={image[1]} key={idx} />
                    ))}
                </Slider>
            </Root>
        );
    }

    private rotateSlider = () => {
        this.setState({
            sliding: true,
        });
    };

    private updateImages = ({ target, currentTarget }) => {
        if (target !== currentTarget) return;
        const images = this.state.images
            .slice(1)
            .concat([this.state.images[0]]);

        this.setState({
            sliding: false,
            images,
        });
    };
}
export default ImageCarousel;

const Root = styled.div`
    display: flex;
    justify-content: flex-start;
    max-width: 1200px;
    padding: 50px 0px;
    margin: 0 auto;

    overflow: hidden;

    @media (max-width: 1200px) {
        max-width: 900px;
    }
    @media (max-width: 900px) {
        max-width: 600px;
    }
    @media (max-width: 600px) {
        max-width: 300px;
    }
`;

const Slider = styled.div<{ sliding: boolean }>`
    display: flex;
    align-items: center;

    transition: ${(props) =>
        props.sliding ? "transform 2s ease-in-out" : "none"};
    transform: ${(props) =>
        props.sliding ? "translateX(-300px)" : "translateX(0)"};

    img {
        width: 220px;
        max-height: 100px;
        margin: 0 40px;
        filter: saturate(0%);
        transition: filter 1s;

        &:hover {
            filter: saturate(100%);
        }
    }
`;
